import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import theme from '../../utils/theme';
import logo from '../../assets/logo.png';
import styled from '@emotion/styled';

const Bar = styled('div')`
    display: block;
    height: 3px;
    width: 33px;
    margin: 8px;
    background-color: ${theme.colors.darkerGreen};
    transition: 150ms ease-in-out transform, 150ms ease-in-out opacity;
`;

const MenuToggle = (props) => {
    return (
        <div
            onClick={() => props.setMobileNavCollapsed(!props.mobileNavCollapsed)}
            css={css`
                @media (min-width: ${theme.breakpoints[0]}px) {
                    display: none;
                }
                height: auto;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                border-radius: 50%;
                ${props.mobileNavCollapsed &&
                    `
                    div:nth-of-type(1) {
                        transform: rotate(-135deg) translate(-7.7px, -7.7px);
                    }
                    div:nth-of-type(2) {
                        opacity: 0;
                    }
                    div:nth-of-type(3) {
                        transform: rotate(135deg) translate(-7.7px, 7.7px);
                    }
                    `}
            `}>
            <Bar />
            <Bar />
            <Bar />
        </div>
    );
};

const Header = (props) => {
    return (
        <div
            css={css`
                background-color: ${theme.colors.transparentWhite};
                position: relative;
                width: 100%;
                height: 100%;
            `}>
            <Link
                to="/"
                css={css`
                    position: absolute;
                    display: block;
                    height: 100%;
                    margin-top: ${theme.paddings.paddingToTextMobile};
                    margin-left: ${theme.paddings.paddingToTextMobile};
                    @media (min-width: ${theme.breakpoints[0]}px) {
                        margin-top: ${theme.paddings.paddingToText};
                        margin-left: ${theme.paddings.paddingToText};
                    }
                `}>
                <img
                    src={logo}
                    alt="logo"
                    css={css`
                        height: 100%;
                    `}
                />
            </Link>
            <MenuToggle
                mobileNavCollapsed={props.mobileNavCollapsed}
                setMobileNavCollapsed={props.setMobileNavCollapsed}
            />
        </div>
    );
};

export default Header;

import { css } from '@emotion/core';

export const clearfix = css`
    &::after {
        content: ' ';
        display: block;
        height: 0;
        clear: both;
    }
`;

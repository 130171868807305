import React from 'react';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import theme from '../../utils/theme';

const MyBackground = (props) => {
	return (
		<Link
			to={props.data.to}
			css={css`
				display: block;
				width: 100%;
				text-decoration: none;
				color: #3e3b3b;
				background-color: ${theme.colors.warmWhite};
				font-family: Verdana;
				box-sizing: border-box;
				border: ${theme.paddings.paddingToTextMobile} solid ${theme.colors.transparentWhite};
				@media (min-width: ${theme.breakpoints[0]}px) {
					background-color: ${theme.colors.transparentWhite};
					border: 0;
				}
			`}>
			<Img fluid={props.data.img.childImageSharp.fluid} alt="" />
			<div
				css={css`
					padding: 15px 0 0 0;
					@media (min-width: ${theme.breakpoints[0]}px) {
						padding: 15px 20px 20px ${theme.paddings.paddingToText};
					}
					p {
						@media (min-width: ${theme.breakpoints[0]}px) {
							font-size: 18px;
						}
						font-size: 20px;
						margin: 0;
					}
					ul {
						margin: 0;
						padding-left: 0;
						list-style-type: none;
					}
					li {
						@media (min-width: ${theme.breakpoints[0]}px) {
							font-size: 14px;
						}
						font-size: 16px;
						margin-bottom: 10px;
					}
					li:first-of-type {
						margin-top: 15px;
					}
					li:last-of-type {
						margin-bottom: 0;
					}
				`}>
				<p>{props.data.heading}</p>
				<ul>
					{props.data.qualifikationen.map((item, key) => {
						return <li key={key}>{item}</li>;
					})}
				</ul>
			</div>
		</Link>
	);
};

export default MyBackground;

import React from 'react';
import { css } from '@emotion/core';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Content from '../components/Content';
import theme from '../utils/theme';
import listItem from '../assets/list-item.png';

const marginToBottom = '15px';

const textStyles = css`
    color: ${theme.colors.darkGrey};
    font-family: Verdana;
    h1  {
        font-size: 24px;
        color: ${theme.colors.warmRed};
        font-weight: normal;
        margin: 0 0 17px 0;
    }
    h2 {
        font-weight: normal;
        color: ${theme.colors.warmRed};
        font-size: 21px;
        margin: 22px 0 17px 0;
    }
    h3,
    h4,
    h5,
    h6,
    h7 {
        font-size: ${theme.fontSizes.baseText};
        color: ${theme.colors.darkGreen};
        font-weight: bold;
        margin: 0 0 5px 0;
    }
    p {
        font-size: ${theme.fontSizes.baseText};
        margin: 0 0 ${marginToBottom} 0;
    }
    p strong,
    li strong {
        color: ${theme.colors.darkGreen};
    }
    p > img {
        display: block;
        max-width: 100%;
        max-height: 300px;
        margin: 0 auto;
    }
    p span.gatsby-resp-image-wrapper {
        max-width: initial !important;
        position: initial !important;
        display: inline !important;
    }
    p a.gatsby-resp-image-link {
        display: inline !important;
    }
    p span.gatsby-resp-image-background-image {
        display: none !important;
    }

    p img.gatsby-resp-image-image {
        display: block !important;
        position: initial !important;
        max-width: 100% !important;
        max-height: 300px !important;
        width: auto !important;
        height: auto !important;
        margin: 0 auto !important;
    }
    a {
        font-size: ${theme.fontSizes.baseText};
        color: ${theme.colors.darkGrey};
        text-decoration: underline;
    }
    ul,
    ol {
        padding-left: 40px;
        margin: 0 0 ${marginToBottom} 0;
        font-size: ${theme.fontSizes.baseText};
    }
    ol {
        list-style: url(${listItem});
    }
    ol ul {
        list-style: disc;
    }
    ul li,
    ol li {
        margin-bottom: 3px;
    }
    ul li p,
    ol li p {
        margin-bottom: 3px;
    }
    @media (min-width: ${theme.breakpoints[0]}px) {
        h1  {
            font-size: 27px;
        }
        h2 {
            font-size: 23;
        }
    }
`;

const Note = (props) => {
    return (
        <div
            css={css`
                border: 1px solid ${theme.colors.greenBorder};
                background-color: ${theme.colors.greenBackground};
                margin-bottom: ${marginToBottom};
                padding: 8px;
                h3 {
                    margin: 0 0 4px 0;
                    font-size: 15px;
                    color: ${theme.colors.darkerGreen};
                }
                p {
                    margin: 0 0 4px 0;
                    font-size: 15px;
                }
                p:last-of-type {
                    margin-bottom: 0;
                }
            `}>
            <h3>{props.heading}</h3>
            <Content markdown={props.text} />
        </div>
    );
};

export const IndexTemplate = (props) => {
    return (
        <div
            css={css`
                ${textStyles}
                padding: 25px ${theme.paddings.paddingToTextMobile} ${theme.paddings.paddingToTextMobile} ${theme.paddings.paddingToTextMobile};
                @media (min-width: ${theme.breakpoints[0]}px) {
                    padding: 40px ${theme.paddings.paddingToText} ${theme.paddings.paddingToText} ${theme.paddings.paddingToText};
                }
                min-height: 300px;
                background-color: ${theme.colors.warmWhite};
            `}>
            <h1>{props.heading}</h1>
            {props.note && props.note.heading && props.note.text && <Note heading={props.note.heading} text={props.note.text} />}
            <Content html={props.text} />
        </div>
    );
};

const Index = (props) => {
    return (
        <Layout pageTitle={props.data.markdownRemark.frontmatter.pageTitle}>
            <IndexTemplate
                note={props.data.markdownRemark.frontmatter.note}
                heading={props.data.markdownRemark.frontmatter.heading}
                text={props.data.markdownRemark.html}
            />
        </Layout>
    );
};

export const query = graphql`
    query($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                pageTitle
                heading
                note {
                    heading
                    text
                }
            }
        }
    }
`;

export default Index;

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link } from 'gatsby';
import logoRed from '../../assets/logo-red.png';
import logoWhite from '../../assets/logo-white.png';
import theme from '../../utils/theme';
import MyBackground from './MyBackground'

const Widget = styled('div')`
    width: 100%;
    margin-bottom: ${theme.margins.marginBetweenContainers1};
`;

const Button = (props) => {
    const backgroundColor = props.index % 2 === 0 ? theme.colors.warmRed : theme.colors.warmWhite;
    const fontColor = props.index % 2 === 0 ? theme.colors.warmWhite : theme.colors.warmRed;
    const logoSrc = props.index % 2 === 0 ? logoWhite : logoRed;
    return (
        <Link
            to={props.to}
            css={css`
                display: block;
                width: calc(100%-20px);
                height: 100px;
                padding: 10px;
                position: relative;
                background-color: ${backgroundColor};
                color: ${fontColor};
                text-decoration: none;
                font-size: 0.95em;
                font-family: Verdana;
            `}>
            <img
                src={logoSrc}
                alt="red logo"
                css={css`
                    width: 50px;
                    position: absolute;
                    top: 0;
                    right: 5px;
                `}
            />
            <div
                css={css`
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    width: 60%;
                `}>
                {props.children}
            </div>
        </Link>
    );
};

const WidgetColumn = (props) => {
    const { buttons, mein_hintergrund } = props.widgetsData.frontmatter;
    return (
        <div
            css={css`
                @media (min-width: ${theme.breakpoints[1]}px) {
                    ${props.inColumn === 'left' && 'display: none;'}
                }
            `}>
            <div
                css={css`
                    float: ${props.inColumn === 'right' ? 'right' : 'left'};
                    width: calc(100% - ${theme.margins.marginBetweenContainers1});
                `}>
                <Widget>
                    {buttons.map((item, index) => {
                        return (
                            <Button to={item.to} key={index} index={index}>
                                {item.text}
                            </Button>
                        );
                    })}
                </Widget>
                <Widget>
                    <MyBackground data={mein_hintergrund} />
                </Widget>
            </div>
        </div>
    );
};

export default WidgetColumn;

import React, { useState } from 'react';
import { css } from '@emotion/core';
import isHash from '../utils/isHash';
import Link from '../components/Link';
import theme from '../utils/theme';

const NavItem = (props) => {
    const [isSubListShown, setIsSubListShown] = useState(props.navItem.status === 'partlyActive');

    return (
        <li>
            <div>
                {props.navItem.children ? (
                    <>
                        <Link
                            onClick={() => setIsSubListShown(!isSubListShown)}
                            css={css`
                                cursor: pointer;
                                color: ${props.navItem.status === 'partlyActive'
                                    ? '#272424'
                                    : '#3e3b3b'};
                            `}>
                            {props.navItem.item}
                        </Link>
                        <NavItemList
                            navItems={props.navItem.children}
                            level={props.level + 1}
                            isSubListShown={isSubListShown}
                        />
                    </>
                ) : (
                    <Link
                        to={props.navItem.to}
                        css={css`
                            color: ${props.navItem.status === 'active' && !isHash(props.navItem.to)
                                ? '#a5281f'
                                : '#3e3b3b'};
                        `}>
                        {props.navItem.item}
                    </Link>
                )}
            </div>
        </li>
    );
};

const NavItemList = (props) => {
    return (
        <ul
            css={css`
                list-style: none;
                margin: 0;
                padding: 0;
                font-family: Verdana;
                div {
                    padding: 6px 0;
                }
                a { 
                    text-decoration: none;
                    transition: color 300ms;
                }
                
                a:hover {
                    color: #a5281f;
                }
                ${props.level === 0 &&
                    `
                    font-size: 16px;
                    @media (min-width: ${theme.breakpoints[0]}px) {
                        font-size: 18px;
                    }
                    
                `}
                ${props.level === 1 &&
                    `
                    padding-left: 20px;
                    font-size: 15px;
                    li {
                        transition: max-height ease-in-out 500ms;
                        max-height: 0;
                        overflow: hidden;
                    }
                `}
                ${props.level === 2 &&
                    `
                    padding-left: 20px;
                    font-size: 13px;
                `}
                ${props.isSubListShown &&
                    `
                    >li {
                        max-height: 300px;
                    }
                `}
            `}>
            {props.navItems.map((navItem, index) => {
                return <NavItem key={index} navItem={navItem} level={props.level} />;
            })}
        </ul>
    );
};

export default NavItemList;

import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { clearfix } from '../../utils/styles';
import theme from '../../utils/theme';
import Content from '../Content';
import Flex from '../Flex';

const marginBottom = '18px';

const Row = styled('div')`
    width: 100%;
    margin-bottom: 12px;
    ${(props) => props.top && `margin: 0px 0 ${marginBottom};`}
    ${(props) => props.bottom && 'margin-bottom: 0;'}
`;

const Column = styled('div')`
    margin-bottom: ${marginBottom};
    @media (min-width: ${theme.breakpoints[0]}px) {
        margin-bottom: 0;
        ${(props) => props.left && 'flex: 0 1 30%; padding-right: 10px;'}
        ${(props) => props.right && 'flex: 0 1 70%;'}
    }
`;

const Footer = (props) => {
    return (
        <div>
            <div
                css={css`
                    width: calc(100%-40px);
                    margin-top: ${theme.margins.marginBetweenContainers0};
                    padding: ${theme.paddings.paddingToTextMobile};
                    background-color: ${theme.colors.warmWhite};
                    border-bottom: 1px solid #ddd;
                    color: ${theme.colors.darkerGreen};
                    font-size: ${theme.fontSizes.baseText};
                    @media (min-width: ${theme.breakpoints[0]}px) {
                        margin-top: ${theme.margins.marginBetweenContainers1};
                        padding: ${theme.paddings.paddingToText};
                    }
                    ul {
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                    }
                    p {
                        margin: 0 0 3px;
                    }
                    h2 {
                        font-size: 25px;
                        margin: 0;
                    }
                    a {
                        color: ${theme.colors.darkerGreen};
                        text-decoration: underline;
                    }
                `}
                id='Kontakt'>
                <Row top>
                    <h2>{props.footerData.frontmatter.heading}</h2>
                </Row>
                <Row>
                    <Flex>
                        <Column left>
                            <ul>
                                <li>
                                    {
                                        props.footerData.frontmatter.address
                                            .street
                                    }
                                </li>
                                <li>
                                    {props.footerData.frontmatter.address.city}
                                </li>
                                <li
                                    css={css`
                                        margin: 10px 0;
                                        font-weight: bold;
                                        color: ${theme.colors.darkerGreen};
                                    `}>
                                    {props.footerData.frontmatter.address.phone}
                                </li>
                                <li>
                                    <Content
                                        markdown={
                                            props.footerData.frontmatter.address
                                                .email
                                        }
                                    />
                                </li>
                            </ul>
                        </Column>
                        <Column right>
                            <Content
                                markdown={props.footerData.frontmatter.text}
                            />
                        </Column>
                    </Flex>
                </Row>
                <Row
                    bottom
                    css={css`
                        ${clearfix}
                        a {
                            float: right;
                            margin-left: 10px;
                        }
                    `}>
                    <Link to='/impressum'>Impressum</Link>
                    <Link to='/datenschutz'>Datenschutz</Link>
                </Row>
            </div>
            <iframe
                css={css`
                    width: 100%;
                    height: 240px;
                `}
                title='Google Maps Karte'
                frameBorder='0'
                src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJpYVM1O90q0cRPSQCBWM37Nw&key=${process.env.GATSBY_MAPS_API_KEY}`}
                allowFullScreen></iframe>
        </div>
    );
};

export default Footer;

import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from '@reach/router';
import matchPaths from '../../utils/matchPaths';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import { withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';
import useSiteMetadata from './../SiteMetadata';
import theme from '../../utils/theme';
import background from '../../assets/background.jpg';
import Flex from '../Flex';
import Nav from './Nav';
import WidgetColumn from './WidgetColumn';
import Footer from './Footer';
import Header from './Header';
import MobileNav from './MobileNav';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Link from '../Link';
import scrollUp from '../../assets/scroll-to-top.png';
import MyBackground from './MyBackground';

const Column = styled('div')`
    ${(props) => props.left && 'display: none;'}
    ${(props) => props.right && 'display: none;'}
    @media (min-width: ${theme.breakpoints[0]}px) {
        ${(props) => props.left && 'display: block;'}
    }
    @media (min-width: ${theme.breakpoints[1]}px) {
        ${(props) => props.left && 'display: block;  flex: 0 1 20%;'}
        ${(props) => props.right && 'display: block;  flex: 0 1 21%;'}
        ${(props) => props.center && 'flex: 0 1 59%;'}
    }
    @media (min-width: ${theme.breakpoints[2]}px) {
        ${(props) => props.left && 'flex-basis: 20%;'}
        ${(props) => props.right && 'flex-basis: 17%;'}
        ${(props) => props.center && 'flex-basis: 63%;'}
    }
`;

const HeaderRow = styled('div')`
    width: 100%;
    height: 11.2vh;
    @media (min-width: ${theme.breakpoints[0]}px) {
        height: 130px;
    }
`;

const globalStyles = css`
    html {
        font-family: Verdana;
        background-attachment: fixed;
        background-image: url(${background});
    }
    body {
        margin: 0;
        padding: 0;
    }
`;

const addStatus = (navItems, pathname) => {
    let active = false;
    const recurseOver = (items) => {
        for (let key in items) {
            if (matchPaths(items[key].to, pathname)) {
                items[key].status = 'active';
                active = true;
                break;
            }

            if (items[key].children) {
                recurseOver(items[key].children);
            }

            if (active) {
                items[key].status = 'partlyActive';
                break;
            }
        }
    };
    recurseOver(navItems);
    return navItems;
};

const removeStatus = (navItems) => {
    for (let key in navItems) {
        if (navItems[key].status === 'active' || 'partlyActive') {
            navItems[key].status = '';
        }
        if (navItems[key].children) {
            removeStatus(navItems[key].children);
        }
    }
};

const Layout = (props) => {
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    useScrollPosition(
        ({ currPos }) => {
            const isShow = currPos.y < -400;
            if (isShow !== showScrollToTop) setShowScrollToTop(isShow);
        },
        [showScrollToTop],
        undefined,
        undefined,
        200
    );

    const [navItems, setNavItems] = useState([]);
    const location = useLocation();
    useEffect(() => {
        setNavItems(addStatus(props.navItems, location.pathname));
        return () => {
            setNavItems(removeStatus(navItems));
        };
    }, [props.navItems, location.pathname, navItems]);

    const [mobileNavCollapsed, setMobileNavCollapsed] = useState(false);

    const siteMetadata = useSiteMetadata();
    const title = `${siteMetadata.title} - ${props.pageTitle}`;
    const { description } = siteMetadata;
    return (
        <div
            id='top'
            css={css`
                z-index: 1;
                position: relative;
                width: calc(100% - 20px);
                height: 200%;
                max-width: 1400px;
                margin: 0 auto;

                padding: 10px;
                @media (min-width: ${theme.breakpoints[0]}px) {
                    width: calc(100% - 40px);
                    padding: 40px 20px 20px;
                }
            `}>
            <Helmet>
                <html lang='de' />
                <title>{title}</title>
                <meta name='description' content={description} />
                <link
                    rel='apple-touch-icon'
                    sizes='180x180'
                    href={`${withPrefix('/')}metaSite/apple-touch-icon.png`}
                />
                <link
                    rel='icon'
                    type='image/png'
                    sizes='32x32'
                    href={`${withPrefix('/')}metaSite/favicon-32x32.png`}
                />
                <link
                    rel='icon'
                    type='image/png'
                    sizes='16x16'
                    href={`${withPrefix('/')}metaSite/favicon-16x16.png`}
                />
                <link
                    rel='manifest'
                    href={`${withPrefix('/')}metaSite/site.webmanifest`}
                />
                <link
                    rel='mask-icon'
                    href={`${withPrefix(
                        '/'
                    )}metaSite/safari-pinned-tab.svg" color="#da4b40`}
                />
                <meta name='msapplication-TileColor' content='#2b5797' />
                <meta name='theme-color' content='#ffffff' />
            </Helmet>
            <Link
                to='#top'
                css={css`
                    transition: opacity ease-in-out 150ms;
                    opacity: ${showScrollToTop ? `1` : `0`};
                    z-index: 99;
                    position: fixed;
                    right: 10px;
                    bottom: 10px;
                    @media (min-width: ${theme.breakpoints[0]}px) {
                        bottom: 20px;
                        right: 20px;
                    }
                `}>
                <img
                    src={scrollUp}
                    css={css`
                        width: 60px;
                    `}
                    alt='nach oben'
                />
            </Link>
            <div
                css={css`
                    width: 100%;
                    overflow: hidden;
                `}>
                <Flex>
                    <Column left>
                        <HeaderRow></HeaderRow>
                        <Nav navItems={navItems} />
                        <WidgetColumn
                            widgetsData={props.widgetsData}
                            inColumn='left'
                        />
                    </Column>
                    <Column center>
                        <HeaderRow>
                            <Header
                                setMobileNavCollapsed={setMobileNavCollapsed}
                                mobileNavCollapsed={mobileNavCollapsed}
                            />
                        </HeaderRow>
                        <MobileNav
                            mobileNavCollapsed={mobileNavCollapsed}
                            navItems={navItems}
                            buttons={props.widgetsData.frontmatter.buttons}
                        />
                        {props.children}
                        <div
                            css={css`
                                margin-top: ${theme.margins
                                    .marginBetweenContainers0};
                                @media (min-width: 450px) {
                                    display: none;
                                }
                            `}>
                            <MyBackground
                                data={
                                    props.widgetsData.frontmatter
                                        .mein_hintergrund
                                }
                            />
                        </div>
                        <Footer footerData={props.footerData} />
                    </Column>
                    <Column right>
                        <HeaderRow></HeaderRow>
                        <WidgetColumn
                            widgetsData={props.widgetsData}
                            inColumn='right'
                        />
                    </Column>
                </Flex>
            </div>
        </div>
    );
};

// querying data, global styles
const LayoutWrapper = (props) => {
    return (
        <>
            <Global styles={globalStyles} />
            <StaticQuery
                query={graphql`
                    query IndexQuery {
                        site {
                            siteMetadata {
                                menuLinks {
                                    item
                                    to
                                    children {
                                        item
                                        to
                                        children {
                                            item
                                            to
                                        }
                                    }
                                }
                            }
                        }
                        footer: markdownRemark(
                            frontmatter: { templateKey: { eq: "footer" } }
                        ) {
                            frontmatter {
                                heading
                                address {
                                    street
                                    city
                                    phone
                                    email
                                }
                                text
                            }
                        }
                        widgets: markdownRemark(
                            frontmatter: { templateKey: { eq: "widgets" } }
                        ) {
                            frontmatter {
                                buttons {
                                    text
                                    to
                                }
                                mein_hintergrund {
                                    img {
                                        ...personImg
                                    }
                                    to
                                    heading
                                    qualifikationen
                                }
                            }
                        }
                    }
                `}
                render={(data) => {
                    return (
                        <Layout
                            navItems={data.site.siteMetadata.menuLinks}
                            widgetsData={data.widgets}
                            pageTitle={props.pageTitle}
                            footerData={data.footer}>
                            {props.children}
                        </Layout>
                    );
                }}
            />
        </>
    );
};

export default LayoutWrapper;

import styled from '@emotion/styled';
import theme from '../utils/theme';

export default styled('div')`
    width: 100%;
    @media (min-width: ${theme.breakpoints[0]}px) {
        display: flex;
        justify-content: space-between;
    }
`;

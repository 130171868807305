import React from 'react';
import { css } from '@emotion/core';
import theme from '../../utils/theme';
import Menu from '../Menu';

const Nav = (props) => {
    return (
        <nav
            css={css`
                background-color: ${theme.colors.transparentWhite};
                width: calc(100%-40px);
                margin-bottom: ${theme.margins.marginBetweenContainers1};
                padding: 10px 30px 10px 10px;
                overflow: hidden;
            `}>
            <Menu navItems={props.navItems} level={0} />
        </nav>
    );
};

export default Nav;

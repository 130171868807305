import React from 'react';
import { css } from '@emotion/core';
import theme from '../../utils/theme';
import Menu from '../Menu';
import Link from '../Link';

const Button = (props) => {
    const backgroundColor = props.index % 2 === 0 ? theme.colors.warmRed : theme.colors.warmWhite;
    const fontColor = props.index % 2 === 0 ? theme.colors.warmWhite : theme.colors.warmRed;

    return (
        <Link
            to={props.buttonData.to}
            css={css`
                background-color: ${backgroundColor};
                color: ${fontColor};
                font-size: 12px;
                text-decoration: none;
            `}>
            {props.buttonData.text}
        </Link>
    );
};

const MobileNav = (props) => {
    return (
        <nav
            css={css`
                transition: max-height ease-in-out 400ms;
                max-height: 0;
                overflow: hidden;
                background-color: ${theme.colors.transparentWhite};
                ${props.mobileNavCollapsed && `max-height: 1000px;`}
                @media (min-width: ${theme.breakpoints[0]}px) {
                    display: none;
                }
            `}>
            <div
                css={css`
                    padding: 20px ${theme.paddings.paddingToTextMobile} 5px;
                `}>
                <Menu navItems={props.navItems} level={0} />
            </div>
        </nav>
    );
};

export default MobileNav;
